
// @ is an alias to /sr
import {
  defineComponent,
  inject,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import InputWrapText from "@/components/atomics/InputWrapText.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import Chip from "@/components/atomics/Chip.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import FolderPath from "@/components/atomics/FolderPath.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import { formatFolderPath, getListFolderFromPath } from "@/lib/utility/common";
import {
  FolderRepository,
  GroupRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "GroupTemplate",
  components: {
    AfterLoginPageLayout,
    FlatButton,
    TextButton,
    Dropdown,
    Chip,
    BreadScrum,
    MenuOptionPopup,
    FolderPath,
    InputWrapText,
  },
  props: {
    groupID: { type: String, required: true },
    groupName: { type: String, required: true },
  },
  setup(props) {
    const { t } = useI18n();
    const state = reactive({
      breadScrums: [] as any,
      groupPath: [] as any,
      permissions: [
        { id: "1", name: t("groupTemplate.readWrite") },
        { id: "2", name: t("groupTemplate.readOnly") },
      ],
      viewOnly: [{ id: "2", name: t("groupTemplate.readOnly") }],
      showMenuOptionFolderIndex: -1,
      showMenuOptionContractIndex: -1,
      selectedPermission: "1",
      lowestPermission: "1",
      groupRole: {
        canUpdate: false,
        canDelete: false,
      },
    });

    const isMobile = inject("isMobile");
    const items = reactive({
      folders: [],
      contracts: [],
      page: 1,
      total: 0,
    });
    const selectedItems = reactive({
      role: 1,
      folders: [],
      contracts: [],
    });
    watch(
      () => selectedItems.folders.length,
      () => {
        state.lowestPermission = "1";
        state.selectedPermission = "1";
        selectedItems.folders.forEach((item: any) => {
          if (item.role == "2") {
            state.lowestPermission = "2";
            state.selectedPermission = "2";
          }
        });
        selectedItems.contracts.forEach((item: any) => {
          if (item.role == "2") {
            state.lowestPermission = "2";
            state.selectedPermission = "2";
          }
        });
      },
      { deep: true }
    );
    watch(
      () => selectedItems.contracts.length,
      () => {
        state.lowestPermission = "1";
        state.selectedPermission = "1";
        selectedItems.folders.forEach((item: any) => {
          if (item.role == "2") {
            state.lowestPermission = "2";
            state.selectedPermission = "2";
          }
        });
        selectedItems.contracts.forEach((item: any) => {
          if (item.role == "2") {
            state.lowestPermission = "2";
            state.selectedPermission = "2";
          }
        });
      },
      { deep: true }
    );
    const searchKeyword = ref("");
    let delay: ReturnType<typeof setTimeout>;

    const itemInFolders = reactive({
      folders: [],
      contracts: [],
      page: 1,
      total: 0,
    });

    const {
      getListFolderContract,
      getItemsGroup,
      addFolderItemsGroup,
      updateFolderPermission,
      updateContractPermission,
      deleteGroupFolder,
      deleteGroupContract,
    } = groupFolderMethods();

    const store = useStore();

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };
    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const search = (value: string) => {
      searchKeyword.value = value;
      state.groupPath = [];
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const payload = await getListFolderContract({
          isTemplateFolder: true,
          withParentFolderPath: true,
          page: 1,
          keyword: searchKeyword.value,
          groupId: props.groupID,
        });
        items.folders = payload.folders.data;
        items.contracts = payload.contracts.data;
        items.page = 1;
        items.total =
          (payload.folders.pagination.totalRecords ?? 0) +
          (payload.contracts.pagination.totalRecords ?? 0);
        state.groupPath = getListFolderFromPath(
          items.folders[0]["parentFolderPath"]
        );
      }, 300);
    };

    const checkSubFolder = async (folder: any) => {
      state.groupPath.push(folder);
      searchKeyword.value = "";
      const payload = await getListFolderContract({
        isTemplateFolder: true,
        withParentFolderPath: true,
        page: 1,
        keyword: searchKeyword.value,
        folderId: folder.id,
        groupId: props.groupID,
      });
      items.folders = payload.folders.data;
      items.contracts = payload.contracts.data;
      items.page = 1;
      items.total =
        payload.folders.pagination.totalRecords +
        payload.contracts.pagination.totalRecords;
    };

    const clickRootPath = async () => {
      state.groupPath = [];
      const itemToAdd = await getListFolderContract({
        isTemplateFolder: true,
        withParentFolderPath: true,
        groupId: props.groupID,
      });
      items.folders = itemToAdd.folders.data;
      items.contracts = itemToAdd.contracts.data;
      items.total =
        (itemToAdd.folders.pagination.totalRecords ?? 0) +
        (itemToAdd.contracts.pagination.totalRecords ?? 0);
    };

    const clickFolderPath = async (folder: any) => {
      const index = state.groupPath.indexOf(folder);
      if (index > -1) {
        state.groupPath.splice(index + 1, state.groupPath.length);
      }
      const payload = await getListFolderContract({
        isTemplateFolder: true,
        withParentFolderPath: true,
        page: 1,
        keyword: searchKeyword.value,
        folderId: folder.id,
        groupId: props.groupID,
      });
      items.folders = payload.folders.data;
      items.contracts = payload.contracts.data;
      items.page = 1;
      items.total =
        payload.folders.pagination.totalRecords +
        payload.contracts.pagination.totalRecords;
    };

    const onAddFolderToGroup = async () => {
      const contractArr = [] as string[];
      const folderArr = [] as string[];

      selectedItems.contracts.forEach((item: any) => contractArr.push(item.id));
      selectedItems.folders.forEach((item: any) => folderArr.push(item.id));

      const payload = {
        folders: folderArr,
        contracts: contractArr,
        role: parseInt(state.selectedPermission),
      };

      await addFolderItemsGroup(props.groupID, payload)
        .then(async (response) => {
          if (response) {
            //add items local if success from API
            // selectedItems.contracts.forEach((item: any) =>
            //   itemInFolders.contracts.push({
            //     ...item,
            //     role: parseInt(state.selectedPermission),
            //   } as never)
            // );
            // selectedItems.folders.forEach((item: any) =>
            //   itemInFolders.folders.push({
            //     ...item,
            //     role: parseInt(state.selectedPermission),
            //   } as never)
            // );
            // itemInFolders.total =
            //   itemInFolders.total +
            //   selectedItems.folders.length +
            //   selectedItems.contracts.length;

            //remove item from list when success
            selectedItems.contracts.forEach((item: any) => {
              const index = items.contracts.indexOf(item as never);
              if (index >= 0)
                (items.contracts[index] as any).excludeFlag = true;
            });
            selectedItems.folders.forEach((item: any) => {
              const index = items.folders.indexOf(item as never);
              if (index >= 0) (items.folders[index] as any).excludeFlag = true;
            });

            //reset choosen
            selectedItems.folders.splice(0, selectedItems.folders.length);
            selectedItems.contracts.splice(0, selectedItems.contracts.length);
            state.selectedPermission = "1";

            const data = await getItemsGroup({
              isTemplateFolder: true,
              groupId: props.groupID,
            });
            itemInFolders.folders = data.folders.data;
            itemInFolders.contracts = data.contracts.data;
            itemInFolders.total =
              (data.folders.pagination.totalRecords ?? 0) +
              (data.contracts.pagination.totalRecords ?? 0);
            showSuccessNotification(t("notification.createSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const onUpdateFolderPermission = async (folder: any, role: string) => {
      const payload = {
        folderId: folder.id,
        groupId: props.groupID,
        role: parseInt(role),
      };
      await updateFolderPermission(payload)
        .then((response) => {
          if (response) {
            folder.role = role;
          }
          showSuccessNotification(t("notification.updateSuccess"));
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const onUpdateContractPermission = async (contract: any, role: string) => {
      const payload = {
        contractId: contract.id,
        groupId: props.groupID,
        role: parseInt(role),
      };
      await updateContractPermission(payload)
        .then((response) => {
          if (response) {
            contract.role = role;
          }
          showSuccessNotification(t("notification.updateSuccess"));
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const onDeleteGroupFolder = async (item: any) => {
      await deleteGroupFolder(props.groupID, item.id)
        .then((response) => {
          if (response) {
            const index = itemInFolders.folders.findIndex(
              (folder: any) => folder.id === item.id
            );

            itemInFolders.folders.splice(index, 1);

            const indexAtAllFolders = items.folders.findIndex(
              (folder: any) => folder.id === item.id
            );
            if (indexAtAllFolders >= 0)
              (items.folders[indexAtAllFolders] as any).excludeFlag = false;

            itemInFolders.total = itemInFolders.total - 1;
            showSuccessNotification(t("notification.deleteSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const onDeleteGroupContract = async (item: any) => {
      await deleteGroupContract(props.groupID, item.id)
        .then((response) => {
          if (response) {
            const index = itemInFolders.contracts.findIndex(
              (contract: any) => contract.id === item.id
            );

            itemInFolders.contracts.splice(index, 1);

            const indexAtAllFolders = items.contracts.findIndex(
              (contract: any) => contract.id === item.id
            );

            if (indexAtAllFolders >= 0)
              (items.contracts[indexAtAllFolders] as any).excludeFlag = false;

            itemInFolders.total = itemInFolders.total - 1;
            showSuccessNotification(t("notification.deleteSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const PAGE_SIZE_DEFAULT = 20;

    const loadMoreItems = async (e: any) => {
      const bottomOfWindow =
        Math.floor(e.target.scrollTop + e.target.offsetHeight) >=
        e.target.scrollHeight;
      let canLoadMore =
        items.folders.length + items.contracts.length < items.total;

      if (bottomOfWindow && canLoadMore) {
        items.page = items.page + 1;
        const moreItems = await getListFolderContract({
          isTemplateFolder: true,
          withParentFolderPath: true,
          page: items.page,
          groupId: props.groupID,
          keyword: searchKeyword.value,
          pageSize: PAGE_SIZE_DEFAULT,
        });
        for (const index in moreItems.folders.data) {
          items.folders.push(moreItems.folders.data[index] as never);
        }
        for (const index in moreItems.contracts.data) {
          items.contracts.push(moreItems.contracts.data[index] as never);
        }
      }
    };

    const loadMoreItemInFolders = async (scrollElement: any) => {
      // const bottomOfWindow =
      //   Math.floor(scrollElement.scrollTop + scrollElement.offsetHeight) >=
      //   scrollElement.scrollHeight;
      let canLoadMore =
        itemInFolders.folders.length + itemInFolders.contracts.length <
        itemInFolders.total;

      if (canLoadMore) {
        itemInFolders.page = itemInFolders.page + 1;
        const moreItems = await getItemsGroup({
          isTemplateFolder: true,
          page: itemInFolders.page,
          groupId: props.groupID,
        });
        for (const index in moreItems.folders.data) {
          itemInFolders.folders.push(moreItems.folders.data[index] as never);
        }
        for (const index in moreItems.contracts.data) {
          itemInFolders.contracts.push(
            moreItems.contracts.data[index] as never
          );
        }
      }
    };

    onBeforeMount(async () => {
      state.breadScrums = [
        { text: props.groupName, to: "/group" },
        {
          text: t("group.editGroupTemplateTitle"),
          to: `?groupID=${props.groupID}&groupName=${props.groupName}`,
        },
      ];

      const itemToAdd = await getListFolderContract({
        isTemplateFolder: true,
        withParentFolderPath: true,
        groupId: props.groupID,
        pageSize: PAGE_SIZE_DEFAULT,
      });
      items.folders = itemToAdd.folders.data;
      items.contracts = itemToAdd.contracts.data;
      items.total =
        (itemToAdd.folders.pagination.totalRecords ?? 0) +
        (itemToAdd.contracts.pagination.totalRecords ?? 0);

      const data = await getItemsGroup({
        isTemplateFolder: true,
        groupId: props.groupID,
      });
      itemInFolders.folders = data.folders.data;
      itemInFolders.contracts = data.contracts.data;
      itemInFolders.total =
        (data.folders.pagination.totalRecords ?? 0) +
        (data.contracts.pagination.totalRecords ?? 0);

      state.groupRole.canUpdate = data.folders.pagination.canUpdate ?? false;
      state.groupRole.canDelete = data.folders.pagination.canDelete ?? false;
    });
    const isSelected = (item: any) =>
      selectedItems.folders.find((el: any) => el.id === item.id) ||
      selectedItems.contracts.find((el: any) => el.id === item.id);

    const isEnabled = (item: any) => {
      return (
        state.groupRole.canUpdate && !item.excludeFlag && !isSelected(item)
      );
    };

    // onMounted(() => {
    //   const scrollElement = document.querySelector(".left-part > .bottom-part");
    //   scrollElement?.addEventListener("scroll", () =>
    //     loadMoreItemInFolders(scrollElement)
    //   );
    // });

    return {
      ...toRefs(state),
      isMobile,
      itemInFolders,
      items,
      selectedItems,
      loadMoreItems,
      loadMoreItemInFolders,
      search,
      searchKeyword,
      checkSubFolder,
      onAddFolderToGroup,
      onUpdateFolderPermission,
      onUpdateContractPermission,
      onDeleteGroupFolder,
      onDeleteGroupContract,
      formatFolderPath,
      clickRootPath,
      clickFolderPath,
      getListFolderFromPath,

      isSelected,
      isEnabled,
    };
  },
  methods: {
    selectFolder(index: number) {
      const isContain = this.selectedItems.folders.find(
        (item: any) => item.id == (this.items.folders[index] as any).id
      );
      if (!isContain)
        this.selectedItems.folders.push(this.items.folders[index]);
    },
    selectContract(index: number) {
      const isContain = this.selectedItems.contracts.find(
        (item: any) => item.id == (this.items.contracts[index] as any).id
      );
      if (!isContain)
        this.selectedItems.contracts.push(this.items.contracts[index]);
    },
    removeSelectedFolder(index: number) {
      this.selectedItems.folders.splice(index, 1);
    },
    removeSelectedContract(index: number) {
      this.selectedItems.contracts.splice(index, 1);
    },
    showMenuOptionFolder(index: number) {
      if (index == this.showMenuOptionFolderIndex)
        this.showMenuOptionFolderIndex = -1;
      else this.showMenuOptionFolderIndex = index;
    },
    showMenuOptionContract(index: number) {
      if (index == this.showMenuOptionContractIndex)
        this.showMenuOptionContractIndex = -1;
      else this.showMenuOptionContractIndex = index;
    },
  },
});

const groupFolderMethods = () => {
  const { getListFolderContract } =
    RepositoryFactory.getRepository<FolderRepository>(FolderRepository);
  const {
    getItemsGroup,
    addFolderItemsGroup,
    updateFolderPermission,
    deleteGroupFolder,
    updateContractPermission,
    deleteGroupContract,
  } = RepositoryFactory.getRepository<GroupRepository>(GroupRepository);

  return {
    getListFolderContract,
    getItemsGroup,
    addFolderItemsGroup,
    updateFolderPermission,
    deleteGroupFolder,
    updateContractPermission,
    deleteGroupContract,
  };
};
