import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f5976a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event))),
      class: _normalizeClass(["group-title input", { 'margin-top': _ctx.hasMarginTop }]),
      placeholder: _ctx.placeholder
    }, null, 42, _hoisted_1), [
      [_vModelText, _ctx.value]
    ]),
    _withDirectives(_createElementVNode("textarea", {
      ref: "textarea",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInputArea($event))),
      rows: "1",
      style: _normalizeStyle({
      width: this.textareaWidth,
    }),
      class: _normalizeClass(["group-title input textarea-input", { 'margin-top': _ctx.hasMarginTop }]),
      placeholder: _ctx.placeholder
    }, null, 46, _hoisted_2), [
      [_vModelText, _ctx.value]
    ])
  ], 64))
}