
// @ is an alias to /sr
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  name: "InputWrapText",
  components: {},
  props: {
    placeholder: { type: String, required: true },
    hasMarginTop: { type: Boolean, default: false },
  },
  emits: ["input"],
  setup(props) {
    const state = reactive({
      textareaWidth: "0",
      value: "",
    });

    return {
      ...toRefs(state),
    };
  },
  methods: {
    onInput(e: any) {
      this.$emit("input", this.value);
      e.target.style.width = "auto";
      e.target.style.width = `${e.target.scrollWidth}px`;
      const textarea = this.$refs.textarea as HTMLElement;
      const input = this.$refs.input as HTMLElement;
      if (e.target.clientWidth >= e.path[1].clientWidth - 60) {
        input.replaceWith(textarea);
        this.textareaWidth = "100%";
        e.target.style.width = "350px";
        textarea.focus();
      }
    },
    onInputArea(e: any) {
      this.$emit("input", this.value);
      e.target.style.width = "100%";
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
      const textarea = this.$refs.textarea as HTMLElement;
      const input = this.$refs.input as HTMLElement;
      if (this.value == "") {
        textarea.replaceWith(input);
        input.focus();
      }
    },
  },
});
